const status_track_race = {
    'SOLICITANDO': 10,
    'ACEITANDO_CORRIDA': 11,
    'MOTORISTA_DESLOCAMENTO': 20,
    'ANDAMENTO': 30,
    'FINALIZADA': 35,
    'CANCELADA_MOTORISTA': 50,
    'CANCELADA_PASSAGEIRO': 51,
    'SEM_MOTORISTA': 55,
    'SOLICITACAO_EXPIRADA': 56,
    'CANCELADA_GARUPA': 57
}

module.exports = {
    status_track_race
}  